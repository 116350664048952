import React, { useContext, useState } from "react";
import { Row, Col, Divider, notification } from "antd";
import { LanguageContext } from "../../../context/Language";
import { AppContext } from "../../../context/App/AppProvider";
import Modal from "../../Modal";
import Input from "../../Form/Input";
import Select from "../../Form/Select";
import Phone from "../../Form/Phone";
import Button from "../../Buttons/GeneralButton";
import Admin from "../../../classes/admin/admin";
import useStateValidator from "../../../hooks/validators/useStateValidator";

const rules = {
  email: {
    type: "string", min: 1, isEmail: true,
  },
  name: {
    type: "string", min: 2, max: 120, validEmpty: true, isAlphanumeric: true,
  },
  last: {
    type: "string", min: 1, max: 120, validEmpty: true, isAlphanumeric: true,
  },
  companyName: {
    type: "string", min: 3, max: 120, validEmpty: true, isAlphanumeric: true,
  },
  address: {
    type: "string", min: 3, max: 255, validEmpty: true, isAlphanumeric: true,
  },
  city: {
    type: "string", min: 1, max: 120, validEmpty: true, isAlpha: true,
  },
  state: {
    type: "string", min: 1, max: 120, validEmpty: true, isAlpha: true,
  },
  website: {
    type: "string", min: 1, max: 120, validEmpty: true, isURL: true,
  },
  zipCode: {
    type: "string", min: 2, validEmpty: true,
  },
  country: { 
    type: "string",length: 0, validEmpty: true, isAlpha: true,
  },
  phone: {
    type: "string", min: 3, validEmpty: true, numericStr: true,
  },
  ladaCountry: {
    type: "string", length: 0, validEmpty: true, isAlpha: true,
  },
  ladaCode: {
    type: "number", length:0,  validEmpty: true, numericStr: true,
  },
};

const ModalStatus = ({
  statusData,
  hideModalStatus,
  statusUser,
  adduser = false,
  getSearch,
  getAllUsers,
  setIinitialized,
  allEmails,
}) => {

  const { dictionary } = useContext(LanguageContext);
  const { allCountries, ladaOptions } = useContext(AppContext);
  const { validationErrorMessages, validate } = useStateValidator({rules});
  const [email, setEmail] = useState("");
  const [optional, setOptional] = useState({
    name: "",
    last: "",
    companyName: "",
    address: "",
    city: "",
    state: "",
    website: "",
    zipCode: "",
    country: "",
    phone: "",
    ladaCountry: "",
    ladaCode: "",
  });
  const [errors, setError] = useState({});
  
  const changeHandlerEmail = (e) => {
    setError({});
    const value = e.target.value;
    setEmail(value);
  };

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setOptional({
      ...optional,
      [name]: value,
    });
  };

  const handleSelect = (e, name) => {
    setOptional({
      ...optional,
      [name]: e,
    });
  };

  const ladaChangeHandler = (e, name) => {
    setOptional({
      ...optional,
      ladaCountry: e,
      ladaCode: name.code,
    });
  };
  
  const changeStatusInfo = () => {
    return (
      <div>
        {statusData.type === "disable" ?
        dictionary.disabledMessageUserName.replace(
          "--user--",
          statusData.name
        )
      : dictionary.activeMessageUserName.replace(
          "--user--",
          statusData.name
        )}
      </div>
    )
  }
  
  const addUserInfo = () => {
    return (
      <div className="forms">
      <Row className="gutter-row">
        <Col span={24}>
          <Input
            name="email"
            value={email}
            required={true}
            onChange={(e) => changeHandlerEmail(e)}
            label={dictionary.email}
            className={errors.email ? "inputErr" : ""}
            error={errors.email ?? validationErrorMessages?.email?.join(" ")}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Divider orientation="left" style={{ color: "#808080" }}>
            <div className="subtitle-opt">{dictionary.optional}</div>
          </Divider>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} className="col1">
            <Input
              name="name"
              required={false}
              value={optional.name}
              error={validationErrorMessages?.name?.join(" ")}
              onChange={changeHandler}
              label={dictionary.firstName}
              className={validationErrorMessages?.name?.length ? "inputErr" : ""}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} className="col2">
            <Input
              name="last"
              required={false}
              value={optional.last}
              error={validationErrorMessages?.last}
              onChange={changeHandler}
              label={dictionary.lastName}
              className={errors.last ? "inputErr" : ""}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} className="col1">
            <Input
              name="companyName"
              required={false}
              value={optional.companyName}
              onChange={changeHandler}
              error={validationErrorMessages?.companyName}
              label={dictionary.companyName}
              className={validationErrorMessages?.companyName ? "inputErr" : ""}
            />
            </Col>
          <Col xs={24} sm={24} md={12} lg={12} className="col2">
            <Input
              name="address"
              required={false}
              value={optional.address}
              onChange={changeHandler}
              error={validationErrorMessages?.address}
              label={dictionary.address}
              className={validationErrorMessages?.address ? "inputErr" : ""}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} className="col1">
            <Input
              name="city"
              required={false}
              value={optional.city}
              error={validationErrorMessages?.city}
              label={dictionary.city}
              onChange={changeHandler}
              className={validationErrorMessages?.city ? "inputErr" : ""}
            />
            <Input
              name="state"
              required={false}
              value={optional.state}
              error={validationErrorMessages?.state}
              onChange={changeHandler}
              label={dictionary.state}
              className={validationErrorMessages?.state ? "inputErr" : ""}
            />
            <Input
              name="website"
              required={false}
              error={validationErrorMessages?.website}
              value={optional.website}
              onChange={changeHandler}
              label={dictionary.website}
              className={validationErrorMessages?.website ? "inputErr" : ""}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} className="col2">
            <Input
              name="zipCode"
              required={false}
              maxLength={10}
              value={optional.zipCode}
              onChange={changeHandler}
              error={validationErrorMessages?.zipCode}
              label={dictionary.zip}
              className={validationErrorMessages?.zipCode ? "inputErr" : ""}
            />
            <Select
              name="country"
              required={false}
              showSearch={true}
              value={optional.country}
              options={allCountries}
              placeholder={dictionary.select}
              label={dictionary.country}
              onChange={(e) => handleSelect(e, "country")}
              className={
                (validationErrorMessages?.country ? "countErr " : "") + " selector-general"
              }
            />
            <Phone
              name="phone"
              required={false}
              maxLength={20}
              value={optional.phone}
              onChange={changeHandler}
              error={validationErrorMessages?.phone}
              lada={optional.ladaCountry}
              onChangeSelect={ladaChangeHandler}
              phoneCountries={ladaOptions}
              label={dictionary.phoneNumber}
              className={validationErrorMessages?.phone ? "inputErr" : ""}
            />
          </Col>
      </Row>
      </div>
    )
  }

  const sendInvite = () => {
    const errors = validate({email, ...optional});
    if (Object.keys(errors).length) {
      setError(errors);
      return;
    }
    if(allEmails.includes(email)){
      setError({email: dictionary.alreadyEmailOrInvite});
      return;
    }
    try {
      let user={
        email: email,
        verified: true,
      }
      if(Object.values(optional).some(value => value !== "")){
        user.profile = {
          name: optional.name,
          surname: optional.last,
          company_name: optional.companyName,
          website: optional.website,
          phone: {
            code: optional.ladaCode.toString() || "1",
            number: optional.phone,
            country: optional.ladaCountry || "US",
          },
          address: {
            address: optional.address,
            city: optional.city,
            state: optional.state,
            zip: optional.zipCode,
            country: optional.country,
          },
        };
      }
      Admin.sendInvitation({user}).then((res) => {
        if(res.status === 201) {
          notification.success({
            description: dictionary.invitationSent,
          });
        }
      }).catch(() => {
        notification.error({
          description: dictionary.invitationError,
        });
      }).finally(() => {
        setIinitialized(false);
        getAllUsers();
        setError({});
        hideModalStatus();
        getSearch(null);
      });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Modal
      destroyOnClose
      maskClosable={false}
      idModal="softDelete"
      isModalVisible={statusData.show}
      handleCancel={hideModalStatus}
      title={<div className="modal-title">{dictionary[statusData.type]}</div>}
      body={adduser
        ? addUserInfo()
        : changeStatusInfo()
      }
      footer={
        <div className="modalAdd-btns">
          <Button
            key="cancel"
            size="sm"
            className="back-btn"
            text={dictionary.cancel}
            onClick={hideModalStatus}
          />
          <Button
            key="submit"
            size="md"
            text={adduser
              ? dictionary.send
              : dictionary[statusData.type]}
            disabled={statusData.hasRelease > 0}
            className="footer-btn"
            onClick={adduser
              ? () => sendInvite()
              : () => statusUser(statusData.userid, statusData.type)}
          />
        </div>
      }
    />
  );
};

export default ModalStatus;
