import { Button as ButtonAntd, Typography, Popconfirm, Spin, notification, Select } from "antd";
import React, { useContext, useState } from "react";
import { PlusCircleFilled } from "@ant-design/icons";
import { LanguageContext } from "../../../../../context/Language";
import AdminCalls from "../../../../../classes/admin/admin";
import Modal from "../../../../Modal/Modal";
import Button from "../../../../Buttons/GeneralButton";

const UPCSetter = ({
  releaseId,
  disabled,
  upc,
  loadRelease,
  isUpdateUpc,
  setShowAddUpcIsrcModal,
}) => {

  const { Option } = Select;
  const { dictionary } = useContext(LanguageContext);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ openSelectModal, setOpenSelectModal ] = useState(false);

  const assignUPC = () => {
    notification.destroy(); // Clear all notifications
    setIsLoading(true);
    AdminCalls.releaseUPC(releaseId)
      .then((response) => {
        if (!response.error) {
          loadRelease();
          setIsLoading(true);
          setTimeout(() => {
            notification.success({
              key: "releaseDetailNotification",
              placement: "bottomRight",
              message: dictionary.successGeneral,
              duration: 5,
            },400);
          });
        } else {
          if (response.message === "notAvailableUpc"){
            setShowAddUpcIsrcModal(true);
          }
          else{
            notification.error({
              key: "releaseDetailNotification",
              placement: "bottomRight",
              message: dictionary.errorGeneral,
              duration: 4,
            });
          }
        }
      })
      .catch(() => {
        notification.error({
          key: "releaseDetailNotification",
          placement: "bottomRight",
          message: dictionary.errorGeneral,
          duration: 4,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const selectChangeHandler = () => {
    setOpenSelectModal(true);
  };

  const confirmNewUpc = () => {
    setOpenSelectModal(false);
    isUpdateUpc(upc);
    setShowAddUpcIsrcModal(true);
  };

  if (isLoading)
    return (
      <Typography.Title className="mb-0" level={4}>
        UPC:&nbsp;
        <Spin className="sping-assign" />
      </Typography.Title>
    );

  if (upc && !disabled)
    return (
      <Typography.Title className="mb-0 inline-input" level={4}>
        <div className="label-upc">UPC: </div>
        <Select
          name="label"
          value={upc}
          placeholder={dictionary.select}
          onChange={(e) => selectChangeHandler(e)}
          className="selector-general"
          disableOpt={true}
        >
          <Option value={0} className="opt-newupc">
            <PlusCircleFilled/>&nbsp; {dictionary.addNewUPC}
          </Option>
        </Select>
        <Modal
          destroyOnClose
          maskClosable={false}
          open={openSelectModal}
          isModalVisible={setOpenSelectModal}
          addclassName="modalConfirmContract"
          handleCancel={() => setOpenSelectModal(false)}
          title={dictionary.upcConfirm}
          body={
            <div>
              <p>{dictionary.upcConfirmDesc}</p>
              <p>{dictionary.upcConfirmDesc2}</p>
              <p className="upc-note">{dictionary.upcConrfirmNote}</p>
            </div>
          }
          footer={
          <div className="modalAdd-btns">
            <Button
              key="cancel"
              size="sm"
              className="back-btn"
              text={dictionary.cancel}
              onClick={() => setOpenSelectModal(false)}
            />
            <Button
              type="primary"
              text={dictionary.confirm}
              onClick={() => confirmNewUpc()}
              className="footer-btn"
            />
          </div>
          }
        />
      </Typography.Title>
    );

  if (upc && disabled)
    return (
      <Typography.Title className="mb-0" level={4}>
        UPC:&nbsp;{upc}
      </Typography.Title>
    );

  if (disabled)
    return (
      <Typography.Title className="mb-0" level={4}>
        UPC:&nbsp;
        <ButtonAntd disabled={disabled} type={"primary"} className="assign-upc">
          {dictionary.assign}
        </ButtonAntd>
      </Typography.Title>
    );

  return (
    <Typography.Title className="mb-0" level={4}>
      UPC:&nbsp;
      <Popconfirm
        icon={null}
        placement="right"
        onConfirm={assignUPC}
        okText={dictionary.yes}
        cancelText={dictionary.no}
        title={dictionary.upcRequestConfirm}
      >
        <ButtonAntd type={"primary"} className="assign-upc" disabled={disabled}>
          {dictionary.assign}
        </ButtonAntd>
      </Popconfirm>

    </Typography.Title>
  );
};

export default UPCSetter;
