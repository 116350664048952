import React, { useContext, useState, useRef, useEffect } from "react";
import { Spin } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { LanguageContext } from "../../../context/Language";
import { UserContext } from "../../../context/User";
import { ArtistContext } from "../../../context/Artist";
import moment from "moment";
import Bluebird from "bluebird";
import Start from "./Start";
import Rights from "./Rights";
import Tracks from "./Tracks";
import Success from "./Success";
import Inform from "./Information";
import Territory from "./Territories";
import Distribute from "./Distribution";
import Progress from "../../Steps/CreateBar";
import Participants from "./Participants";
import Loader from "../../Loader/Loader";
import Release from "../../../classes/release/release.jsx";

/**
 * Refactor note: Each step should be autosufficient,
 * we need to remove all the high coupled states
 * like the newNumber and stepsFulfilled
 *
 * Improve design idea: delegate the validation responsability to each step,
 * once a step fulfill they state, the step should call a function in the
 * parent component to update the state (onFullfilled) and go to the next step.
 * This way we can remove the high coupled states between the progressbar
 * and the parent component and the steps per se.
 * Also we can centralize the validation logic with an service, a hook, or a pkg
 * like useForm
 */
const CreateRelease = ({ releaseId }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { dictionary } = useContext(LanguageContext);
  const { mainLabel, subGenreObjects } = useContext(UserContext);
  const { frontRoles } = useContext(ArtistContext);

  // Enable or disable go forward or backward
  const [canMove, setCanMove] = useState(true);
  const [loading, setLoading] = useState(true);
  const [section, setSection] = useState(0);
  const [imageUrl, setImage] = useState(null);
  const [releaseData, setReleaseData] = useState(null);
  const [tracks, setTracks] = useState(null);
  // {"step0": true}
  const [stepsFulfilled, setStepsFulfilled] = useState({});
  const [validateStepFn, setValidateStepFn] = useState();
  const [finishOrangeBar, setFinishOrangeBar] = useState("hidden");
  // const [newNumber, setNewNumber] = useState(1);

  const [state, setInput] = useState({
    _id: null,
    format: 0,
    label: mainLabel.id || 0,
    lang: "en",
    isNew: true,
    relName: "",
    relDate: moment(),
    preOrder: null,
    version: "",
    upc: "",
    subgenres: [],
    genres: [],
    parental: "None",
    addArtist: 0,
    artistName: "",
    role: 0,
    cover: null,
    artistPic: null,
    orgRelDate: null,
    barcode: "",
    recordYear: "",
    metadata: "en",
    recordLoc: "",
    infoC: mainLabel.name || "",
    yearC: moment().format("YYYY"),
    infoP: mainLabel.name || "",
    yearP: moment().format("YYYY"),
    holder: mainLabel.name || "",
    asset: [],
    artists: {
      artist: [],
      writer: [],
      producer: [],
      participant: [],
    },
    artistsFront: {
      artist: [],
      writer: [],
      participant: [],
    },
    tracks: [],
    territories: ["WW"],
    regions: [],
    albumPrice: 0,
    trackPrice: 0,
    distros: [],
    audiosalad_status: "",
  });

  const getReleaseData = () =>
    Release.getReleaseDetails(releaseId).then((data) => {
      setTracks(data.tracks);
      // setNewNumber(data.tracks.length + 1);
      setReleaseData(data.release);
    });

  useEffect(() => {
    if (releaseId) getReleaseData();
  }, [releaseId]);

  useEffect(() => {
    setLoading(true);
    let currStep = releaseData?.step || 0;
    let data = {};
    let step = 0;
    for (let i = 0; i <= 7; i++) {
      const active = i < currStep;
      data["step" + i] = active;
      if (active) {
        step = i;
        nextSection(i);
      }
    }
    setStepsFulfilled(data);
    setSection(step);
    setTimeout(() => {
      setFinishOrangeBar("visible");
      setLoading(false);
    }, 1550);
  }, [releaseData]);

  useEffect(() => {
    const fetchData = async () => {
      if (!releaseData) return;

      setLoading(true);
      try {
        // Process image assets
        // eslint-disable-next-line no-undef
        const image = process.env.REACT_APP_S3_BUCKET_CDN_URL
          ? // eslint-disable-next-line no-undef
            process.env.REACT_APP_S3_BUCKET_CDN_URL + releaseData._id
          : releaseData.s3_url;
        await Promise.all(
          releaseData.asset.map(async (el) => {
            if (el.type === "image") {
              const checksum = el?.checksum || "";
              const getResized = el.filename.replace(
                /.jpg|.png|.jpeg|.heic/gi,
                () => "250x250.webp?v=" + checksum
              );
              setImage(image + "/" + getResized);
            }
          })
        );

        // Process preorders
        let preorder = null;
        await Bluebird.each(releaseData.permission, async (el) => {
          if (el.type === "preorder") preorder = moment(el.start);
        });

        // Process subgenres and DSPs
        const subgenres = await Bluebird.map(
          releaseData.genres,
          async (el) => subGenreObjects[el.subgenre_id || el.genre_id]
        );
        const dsps = await Bluebird.map(
          releaseData.dsp_release,
          async (el) => el.dsp_id
        );

        // Process participants
        const allArtist = {
          artist: releaseData.artists || [],
          producer: releaseData.producers || [],
          writer: releaseData.writers || [],
          participant: releaseData.participants || [],
        };
        const participantsLists = await orderFrontParts(allArtist);

        // Process release tracks
        const releaseTracks = await Bluebird.map(tracks, async (el) => {
          const trackSubgenres = await Bluebird.map(
            el.genres,
            async (elg) => subGenreObjects[elg.subgenre_id || elg.genre_id]
          );
          return {
            ...el,
            subgenres: trackSubgenres,
          };
        });

        // Set input state
        setInput((prevState) => ({
          ...prevState,
          _id: releaseId,
          format: releaseData.type,
          label: releaseData.label_id || mainLabel.id,
          lang: releaseData.language || "en",
          isNew: releaseData.is_new,
          relName: releaseData.title || "",
          relDate: moment(releaseData.date) || moment(),
          preOrder: preorder,
          version: releaseData.title_version || "",
          upc: releaseData.upc || "",
          parental: releaseData.advocacy || "None",
          s3_url: releaseData.s3_url,
          asset: releaseData.asset,
          subgenres,
          genres: releaseData.genres,
          orgRelDate: releaseData.original_date || "",
          recordYear: releaseData.recording_year || "",
          barcode: releaseData.barcode || "",
          metadata: releaseData.metadata_lang || "en",
          recordLoc: releaseData.record_location || "",
          infoC: releaseData.copyright?.data || mainLabel.name,
          infoP: releaseData.phonographic?.data || mainLabel.name,
          holder: releaseData.rights_holder || mainLabel.name,
          yearC: releaseData.copyright?.year || moment().format("YYYY"),
          yearP: releaseData.phonographic?.year || moment().format("YYYY"),
          artists: participantsLists.backList,
          artistsFront: participantsLists.frontList,
          tracks: releaseTracks,
          territories: releaseData.territory || ["WW"],
          albumPrice: releaseData.price_tier[0]?.name || 0,
          trackPrice: tracks[0]?.price_tier[0]?.name || 0,
          distros: dsps,
          audiosalad_status: releaseData.audiosalad_status,
        }));

        // Set section and navigation if necessary
        if (
          releaseData.audiosalad_status &&
          (releaseData.audiosalad_status === "Ready to Sent" ||
            releaseData.audiosalad_status === "In Review")
        ) {
          setSection(7);
          for (let k = 0; k <= 7; k++) nextSection(k);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [releaseData, tracks]); // Ensure only necessary dependencies are here

  useEffect(() => {
    if (
      releaseData?.audiosalad_status &&
      releaseData?.audiosalad_status !== "Draft" &&
      releaseData?.audiosalad_status !== "Rejected"
    ) {
      setCanMove(false);
    } else {
      setCanMove(true);
    }
  }, [releaseData?.audiosalad_status]); // Separate effect for managing canMove state

  let orangeBar = useRef(null);

  const orderFrontParts = async (allArtist) => {
    let keys = Object.keys(allArtist);
    let all = [];
    let onlyArtist = [];
    let onlyWrit = [];
    let onlyPart = [];
    let ordered = {
      artist: [],
      writer: [],
      participant: [],
    };
    let orderedFront = ordered;
    //to save the back part
    keys.map((el) => {
      allArtist[el].forEach((element) => {
        all.push(element);
      });
    });

    all.forEach(async (el) => {
      for (let artistRole in frontRoles.artist) {
        if (artistRole === el.role) {
          onlyArtist.push(el);
        }
      }
      for (let writRole in frontRoles.writer) {
        if (writRole === el.role) {
          onlyWrit.push(el);
        }
      }
      for (let partRole in frontRoles.participant) {
        if (partRole === el.role) {
          onlyPart.push(el);
        }
      }
      if (!el.role) {
        el["role"] = "participant";
        onlyPart.push(el);
      }
      ordered = {
        artist: onlyArtist,
        writer: onlyWrit,
        participant: onlyPart,
      };
    });

    //to save the front part
    Object.keys(ordered).forEach(function (el) {
      let newone = ordered[el].map((member) => {
        const role = ordered[el].reduce((acc, cur) => {
          if (cur.name === member.name) {
            acc.push(cur.role);
          }
          return acc;
        }, []);
        return {
          name: member.name,
          role,
          artist_id: member.artist_id,
          _id: member._id,
          image: member.image,
        };
      });

      let dataFilter = newone.filter(
        (value, index, self) =>
          index ===
          self.findIndex(
            (t) => t.place === value.place && t.name === value.name
          )
      );
      orderedFront[el] = dataFilter;
    });

    let newLists = {
      backList: ordered,
      frontList: orderedFront,
    };
    return newLists;
  };

  const nextSection = (n) => {
    document.getElementById("bar" + n).classList.add("fillStep");
    orangeBar.current.classList.add("stepFilled" + n);
  };

  const backSection = (n) => {
    orangeBar.current.classList.remove("stepEmpty" + (n + 1));
    orangeBar.current.classList.remove("stepFilled" + n);
    orangeBar.current.classList.add("stepEmpty" + n);
    if (document.getElementById("bar" + n)) {
      document.getElementById("bar" + n).classList.remove("fillStep");
    }
  };

  const updateStepValidationFn = (validateFunction) => {
    setValidateStepFn(() => validateFunction);
  };

  /**
   * Wrapper for setSection to pass in subcomponents
   * @param  {number} n
   */
  const changeSection = (n) => {
    setSection(n);
  };

  const insertRelease = async () => {
    const id = await Release.createRelease(state);
    if (!id.error) {
      setStepsFulfilled({
        ...stepsFulfilled,
        step0: true,
      });
      navigate(`${location.pathname}?r=${id}`);
      // window.history.replaceState(null, null, "?r=" + id);
      setInput({
        ...state,
        _id: id,
      });
      window.scrollTo(0, 0);
      return { id, error: false };
    } else {
      return {
        id: false,
        error: dictionary[id.error] || dictionary.releaseCheckData,
      };
    }
  };

  const drawSteps = () => {
    if (loading) return <Loader />;

    return section === 0 ? (
      <Start
        createRelease={insertRelease}
        state={state}
        setInput={setInput}
        setValid={setStepsFulfilled}
        validation={stepsFulfilled}
        backSection={backSection}
        nextSection={nextSection}
        validateStep={updateStepValidationFn}
        changeSection={changeSection}
      />
    ) : section === 1 ? (
      <Inform
        createRelease={insertRelease}
        state={state}
        setInput={setInput}
        setValid={setStepsFulfilled}
        validation={stepsFulfilled}
        backSection={backSection}
        nextSection={nextSection}
        validateStep={updateStepValidationFn}
        changeSection={changeSection}
        imageUrl={imageUrl}
        setImage={setImage}
      />
    ) : section === 2 ? (
      <Rights
        state={state}
        setInput={setInput}
        setValid={setStepsFulfilled}
        validation={stepsFulfilled}
        backSection={backSection}
        nextSection={nextSection}
        validateStep={updateStepValidationFn}
        changeSection={changeSection}
      />
    ) : section === 3 ? (
      <Participants
        state={state}
        setInput={setInput}
        setValid={setStepsFulfilled}
        imageUrl={imageUrl}
        validation={stepsFulfilled}
        backSection={backSection}
        nextSection={nextSection}
        validateStep={updateStepValidationFn}
        changeSection={changeSection}
      />
    ) : section === 4 ? (
      <Tracks
        releaseId={releaseId}
        releaseTracks={tracks || []}
        releaseFormat={releaseData?.type}
        releaseData={releaseData}
        state={state}
        updateStepValidationFn={updateStepValidationFn}
        audiosalad_status={releaseData?.audiosalad_status}
        nextSection={nextSection}
        backSection={backSection}
        // I think that this twp props are not necessary
        // can be used inside of the validation function and removed
        // from the component logic
        stepsFulfilled={stepsFulfilled}
        setStepsFulfilled={setStepsFulfilled}
        // I think that this prop can be changed to leave the responsability
        // in this component
        changeSection={changeSection}
        setCanMove={setCanMove}
      />
    ) : /*
      <Tracks
        state={state}
        setInput={setInput}
        setValid={setValid}
        validation={validation}
        backSection={backSection}
        nextSection={nextSection}
        validateStep={validateStep}
        changeSection={changeSection}
        setCanMove={setCanMove}
        newNumber={newNumber}
        setNewNumber={setNewNumber}
      />*/
    section === 5 ? (
      <Territory
        state={state}
        setInput={setInput}
        setValid={setStepsFulfilled}
        validation={stepsFulfilled}
        backSection={backSection}
        nextSection={nextSection}
        validateStep={updateStepValidationFn}
        changeSection={changeSection}
      />
    ) : section === 6 ? (
      <Distribute
        state={state}
        setInput={setInput}
        setValid={setStepsFulfilled}
        setCanMove={setCanMove}
        validation={stepsFulfilled}
        backSection={backSection}
        nextSection={nextSection}
        validateStep={updateStepValidationFn}
        changeSection={changeSection}
      />
    ) : section === 7 ? (
      <Success releaseId={releaseId} />
    ) : null;
  };

  return (
    <div className="create-box">
      <Progress
        orangeBar={orangeBar}
        validation={stepsFulfilled}
        nextSection={canMove ? nextSection : () => {}}
        backSection={canMove ? backSection : () => {}}
        changeSection={canMove ? changeSection : () => {}}
        canMove={canMove}
        validStep={validateStepFn}
        visibility={finishOrangeBar}
      />
      {finishOrangeBar === "visible" ? (
        drawSteps()
      ) : (
        <Spin size="large" className="spinner-release" />
      )}
    </div>
  );
};

export default CreateRelease;
