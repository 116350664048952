import React, {
  useContext,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { LanguageContext } from "../../context/Language";
import { Button } from "antd";
import { FormProvider, useForm } from "react-hook-form";
import ContractCalls from "../../classes/contract/contract";
import Modal from "antd/lib/modal/Modal";
import Loader from "../../components/Loader";
import { useSearchParams, useNavigate } from "react-router-dom";
import CreateContractTabs from "./CreateContractTabs";
import ModalDelete from "../../components/ModalDelete/ModalDelete";
import ModalNotification from "../../components/ModalNotification";

const CreateContract = () => {
  const [searchParams] = useSearchParams();
  const labelId = searchParams.get("labelId");
  const contractId = searchParams.get("c");
  const { dictionary } = useContext(LanguageContext);
  const [isSaving, setIsSaving] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [saveResponse, setSaveResponse] = useState(null);
  const [saveStatus, setSaveStatus] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openNotficationModal, setOpenNotficationModal] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const form = useRef(null);
  const navigate = useNavigate();
  const deletedNotification = useMemo(
    () =>
      deleted
        ? dictionary.deleteContractSuccess
        : dictionary.deleteContractError,
    [deleted]
  );
  const deletedAction = useCallback(
    () => (deleted ? navigate("/contracts") : setOpenNotficationModal(false)),
    [deleted]
  );

  const formMethods = useForm({
    loadState: "unloaded",
    defaultValues: {
      label: labelId,
    },
  });

  const onSubmit = async (data) => {
    setIsSaving(true);
    const contractSave = contractId
      ? await ContractCalls.patchContract(contractId, data)
      : await ContractCalls.saveContract(data);
    if (contractSave.contract) {
      setSaveStatus(true);
      setSaveResponse(dictionary.successSavedContract);
    } else {
      setSaveStatus(false);
      setSaveResponse(
        contractSave.errors
          ? contractSave.errors[0].message
          : dictionary.deleteContractError
      );
    }
    setModalVisible(true);
    setIsSaving(false);
  };

  const save = () => {
    form.current.requestSubmit();
  };

  const deleteContract = async () => {
    const deleted = await ContractCalls.deleteContract(contractId);
    if (!deleted.error) {
      setOpenNotficationModal(true);
      setDeleted(true);
    } else {
      setOpenNotficationModal(true);
      setDeleted(false);
    }
  };

  return (
    <React.Fragment>
      {isSaving && <Loader />}
      <Modal
        className={"modal-box"}
        open={modalVisible}
        closable={true}
        onCancel={() => setModalVisible(false)}
        onOk={() => setModalVisible(false)}
        title={dictionary.createContract}
      >
        <div>
          <h4>{saveStatus ? dictionary.success : dictionary.error}</h4>
          <p>{saveResponse}</p>
        </div>
      </Modal>
      <div className="container-admin">
        <h1>{dictionary.createContract}</h1>
        <FormProvider {...formMethods}>
          <form
            id="form-contract"
            ref={form}
            onSubmit={formMethods.handleSubmit(onSubmit, (e) => console.log(e))}
          >
            <div className="form-header">
              <Button type="primary" onClick={() => save()}>
                Save
              </Button>
              <Button type="danger" onClick={() => setOpenDeleteModal(true)}>
                Delete
              </Button>
            </div>
            <CreateContractTabs />
          </form>
        </FormProvider>
      </div>
      <ModalDelete
        visible={openDeleteModal}
        message={dictionary.deleteContractConfirm}
        onCancel={() => setOpenDeleteModal(false)}
        onDelete={deleteContract}
      />
      <ModalNotification
        visible={openNotficationModal}
        message={deletedNotification}
        onClose={deletedAction}
      />
    </React.Fragment>
  );
};

export default CreateContract;
