import React, { useContext, useState, useEffect } from "react";
import { Dropdown, Button, Breadcrumb } from "antd";
import { UserContext } from "../../../context/User";
import { Link, useLocation } from "react-router-dom";
import { LanguageContext } from "../../../context/Language";
import { DownOutlined, MenuOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import Avatar from "./Avatar/Avatar";
import LogOutIcon from "../../Icon/LogOutIcon";
import SettingsIcon from "../../Icon/SettingsIcon";
import UserCalls from "../../../classes/user/user";
import ReleaseCalls from "../../../classes/release/release";
import "../nav.css";

const UserMenu = ({changeItem, showDrawer}) => {
  
  const location = useLocation();
  const { username, logout, alertPayee, type } = useContext(UserContext);
  const { dictionary } = useContext(LanguageContext);
  const [current, setCurrent] = useState(null);
  const [section, setSection] = useState(null);
  const [subsection, setSubsection] = useState(null);

  useEffect(() => {
    setSubsection(null);
    setSection(null);
    const searchParams = new URLSearchParams(location.search);
    if(location.pathname.includes("release/")){
      ReleaseCalls.getReleaseDetails(searchParams.get("r"))
      .then((data) => {
        setSubsection(data?.release?.title);
      });
    } else if(location.pathname.includes("user/")){
      UserCalls.getUserDetails(searchParams.get("u"))
      .then((data) => {
        setSubsection(data?.user?.profile?.name || data?.user?.profile?.company_name);
      });
    }
    setSection(searchParams.get("section") ?? "/");
    const pathnameWithoutSlash = location.pathname.substring(1);
    setCurrent(pathnameWithoutSlash);
  }, [location.pathname]);

  const items = [
    {
      label: <Link to="/settings">
              <span className="icon-menu-nav"><SettingsIcon color={"#45454599"}/></span>
              {dictionary.settings}
              {type!=="admin" && alertPayee && <ExclamationCircleFilled className="alert-p"/>}
            </Link>,
      key: "/settings",
      onClick: (e) => changeItem(e, true),
    },
    {
      label: <div>
            <span className="icon-menu-nav"><LogOutIcon color={"#45454599"}/></span>
            {dictionary.logOut}
          </div>,
      key: "3",
      onClick: () => {
        logout();
      },
    },
  ];

  const sideMenuOpener = (
    <Button
      text=" "
      size="sm"
      className="side-btn"
      onClick={showDrawer}
      icon={<MenuOutlined />}
    />
  );

  const title = (
    <h1 className="title">
      {current?.includes("/view") || current?.includes("/edit")
        ? (
          <Breadcrumb className="sub-section-nav">
            <Breadcrumb.Item>
              <Link to={section}>{dictionary.path[section] || dictionary.dasboartTitle}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link>{subsection}</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        ): 
        dictionary.path[current?.split("/")[0]] || dictionary.dasboartTitle}
    </h1>
  );

  const lastMenu = () =>{
    return(
      <div className="user-element">
        {sideMenuOpener}
        {title}
        <Dropdown
          menu={{ items }}
          trigger={['hover','click']}
          overlayClassName="drop-user"
        >
          <button
            className="ant-dropdown-link user"
            onClick={(e) => e.preventDefault()}
          >
            <Avatar />
            {type!=="admin" && alertPayee && <div className="alert-payee"></div>}
            <span className="name">
              {username}
            </span>
            <DownOutlined className="down" />
          </button>
        </Dropdown>
      </div>
    )
  }

  return (
    lastMenu()
  );
};

export default UserMenu;
