import BaseClass from "../";

class AdminCalls extends BaseClass {
  artistsByLabelId(labelId) {
    this.setValues(`/admin/label/${labelId}/artists`);
    return this.doGet();
  }

  updateStatus(release) {
    this.setValues("/admin/updateStatus", { release });
    return this.doPost();
  }

  setReleaseInDraft(releaseId, note) {
    this.setValues(`/admin/release/${releaseId}/draft`, note);
    return this.doPatch();
  }

  changePriority(release, priority) {
    this.setValues("/admin/changePriority", { release, priority });
    return this.doPatch();
  }

  releaseUPC(releaseId, upc = null) {
    this.setValues(`/admin/release/${releaseId}/upc`, {upc});
    return this.doPost();
  }

  releaseUpdate(releaseId, body) {
    this.setValues(`/admin/release/${releaseId}`, body);
    return this.doPatch();
  }

  trackISRC(trackId) {
    this.setValues(`/admin/track/${trackId}/isrc`);
    return this.doPost();
  }

  trackCreate(body) {
    this.setValues(`/admin/track`, body);
    return this.doPost();
  }

  trackUpdate(trackId, body) {
    this.setValues(`/admin/track/${trackId}`, body);
    return this.doPatch();
  }

  trackDelete(trackId) {
    this.setValues(`/admin/track/${trackId}`);
    return this.doDelete();
  }

  switchUserMembership(userId) {
    this.setValues("/admin/user/switch-membership", { userId });
    return this.doPost();
  }

  audioSaladScheduleDelivery(body) {
    this.setValues("/admin/release/audiosalad/schedule-delivery", body);
    return this.doPost();
  }

  audioSaladDSPList() {
    this.setValues("/admin/audiosalad/dsps");
    return this.doGet();
  }

  audioSaladDSPUpsert(body) {
    this.setValues("/admin/audiosalad/dsps", body);
    return this.doPatch();
  }

  audioSaladApiStatus() {
    this.setValues("/admin/audiosalad/refresh_token/status");
    return this.doPost();
  }

  refreshAudioSaladApi(token) {
    this.setValues("/admin/audiosalad/refresh_token", { token });
    return this.doPost();
  }

  getAllUsers() {
    this.setValues("/admin/users");
    return this.doGet();
  }

  userStatus(userid, status) {
    this.setValues("/admin/userStatus", { userid, status });
    return this.doPatch();
  }

  userTempPwd(user) {
    this.setValues("/admin/userTempPwd", {
      userid: user.id,
      email: user.email,
      language: user.language,
    });
    return this.doPatch();
  }

  contracts(limit, offset, where, order) {
    this.setValues(
      "/admin/contracts/",
      {},
      { limit, offset, where: JSON.stringify(where), order }
    );
    return this.doGet();
  }

  addContract(curveId, labelId) {
    this.setValues(`/admin/label/${labelId}/contract`, { curveId });
    return this.doPost();
  }

  updateContract(contractId, body) {
    this.setValues(`/admin/contract/${contractId}/`, body);
    return this.doPatch();
  }

  enableContract(contractId) {
    this.setValues(`/admin/contract/${contractId}/enable`);
    return this.doPatch();
  }

  disableContract(contractId) {
    this.setValues(`/admin/contract/${contractId}/suspend`);
    return this.doPatch();
  }

  updatePayee(labelId, payee) {
    this.setValues(`/admin/label/${labelId}/payee`, { payee });
    return this.doPatch();
  }

  saveContractCurve(contractData) {
    this.setValues("/admin/contract/curve", contractData);
    return this.doPost();
  }

  patchContractCurve(contractId, contractData) {
    this.setValues(`admin/contract/curve/${contractId}`, contractData);
    return this.doPatch();
  }

  deleteContractCurve(contractId) {
    this.setValues(`admin/contract/curve/${contractId}`);
    return this.doDelete();
  }

  getContractCurve(contractId) {
    this.setValues(`/admin/contract/curve/${contractId}`);
    return this.doGet();
  }

  returnUpc(upc) {
    this.setValues(`/admin/upcReturn/${upc}`);
    return this.doPatch();
  }

  addArtist(body) {
    this.setValues("/admin/addArtist", body);
    return this.doPost();
  }

  addLabel(body) {
    this.setValues("/admin/addLabel", body);
    return this.doPost();
  }

  sendInvitation(body) {
    this.setValues("/admin/user/invitation", body);
    return this.doPost();
  }

  deleteInvitation(invitationId, email) {
    this.setValues(`/admin/user/invitation/${invitationId}/${email}`);
    return this.doDelete();
  }

  resendInvitation(email) {
    this.setValues("admin/user/resend/invitation", { email });
    return this.doPost();
  }
}

export default new AdminCalls();
