import React, { useContext, useMemo, useState, useEffect } from "react";
import { Col, Row, Typography, Table, notification } from "antd";
import { LanguageContext } from "../../../../context/Language";
import moment from "moment";
import validator from "validator";
import Button from "../../../Buttons/GeneralButton";
import Country from "../../../../classes/country/country";
import ArtistCalls from "../../../../classes/artist/artist";
import Admin from "../../../../classes/admin/admin";
import AddArtist from "../../../Catalog/ArtistList/AddArtist/AddArtist";
import { createFileObjectState, URLFileName } from "../../../../hooks/files/files";
const { isEmail } = validator;
import Loader from "../../../Loader/Loader";

const ArtistsList = ({ artists, userLabels, getArtists, userId }) => {

  const { dictionary } = useContext(LanguageContext);
  const [imageUrl, setImage] = useState([]);
  const [dataCountries, setData] = useState([]);
  const [imageFile, setImageFile] = useState(null);
  const [loadingAdding, setLoading] = useState(false);
  const [loadArtist, setLoadArtist] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [phoneCountries, setPhoneCountries] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [urlOriginal, setImgOriginal] = useState([]);
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [openData, setOpenData] = useState({
    name: "",
    label_id: 0,
    image: "",
    description: "",
    email: "",
    password: "",
    confirmPassword: "",
    countryPhone: "US",
    code: "1",
    number: "",
    address: "",
    city: "",
    state: "",
    country: 0,
    zip: "",
    update: false,
    hasUser: false,
    user_id: userId,
  });

  const columns = [
    {
      title: " ",
      dataIndex: "id",
      className: "id-category",
    },
    {
      title: dictionary.name,
      dataIndex: "name",
      className: "title-category",
    },
    {
      title: dictionary.label,
      dataIndex: "desc",
      className: "title-category",
    },
    {
      title: dictionary.createdAt,
      dataIndex: "createdAt",
      className: "title-category",
    },
    {
      title: dictionary.updated,
      dataIndex: "updated",
      className: "title-category",
    },
  ];

  const dataSource = useMemo(() => {
    let listArray = [];
    artists.map((el, index) => {
      let name = el.name ? el.name : dictionary.noTitle;
      let labelName = el.label
        ? el.label.name
        :  "-";
      let created = el.createdAt
        ? moment.utc(el.createdAt).startOf("day").format("YYYY/MM/DD")
        : null;
      let updated = el.updatedAt
        ? moment.utc(el.updatedAt).startOf("day").format("YYYY/MM/DD")
        : null;
      return listArray.push({
        index: index,
        key: el._id || index,
        id: index + 1,
        name: name,
        desc: labelName,
        createdAt: created,
        updated: updated,
      });
    });
    return (listArray);
  }, [artists]);

  useEffect(() => {
    if (dataCountries.length === 0) getCountries();
  }, [dataCountries.length]);

  const getCountries = async () => {
    const { body } = await Country.countries();
    setData([...body.countries]);
    const phoneCountries = Country.setPhoneCountries();
    setPhoneCountries([...phoneCountries]);
  };

  const onChangeCheckbox = (e) => {
    setOpenData({
      ...openData,
      is_featured: e.target.checked,
    });
  };

  const addArtistInUser = () => {
    setIsModalVisible(true);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const changeHandler = (e, name) => {
    const value = e.target.value;
    setOpenData({
      ...openData,
      [name]: value,
    });
  };

  const handleChange = async ({ file }) => {
    setImageFile(file);
    if (file.status === "uploading") {
      setLoadingImage(true);
      createFileObjectState(file, setImage);
    } else if (file.status === "removed" && imageUrl.length) {
      if (imageUrl[0].uid) {
        if (imageUrl[0].uid.includes("__AUTO__")) {
          await ArtistCalls.deleteImageArtist(imageUrl[0].url, openData);
          getArtists();
        }
        setImageFile(null);
        setImage([]);
      }
    }
    setLoadingImage(false);
  };

  const selectChangeHandler = (e, name) => {
    if (name === "code") {
      const getCode = phoneCountries.find((el) => el._id === e);
      setOpenData({
        ...openData,
        countryPhone: getCode._id,
        code: String(getCode.phone_code),
      });
    } else {
      setOpenData({
        ...openData,
        [name]: e,
      });
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    let hasError,
      errorAddress,
      hasAddress = false;
    let errorsCopy = {
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      label_id: 0,
      address: "",
      city: "",
      state: "",
      zip: "",
    };
    if (!openData.name) {
      hasError = true;
      errorsCopy.name = "emptyField";
    }
    if (!openData.label_id || openData.label_id === 0) {
      hasError = true;
      errorsCopy.label_id = "selectErr";
    }
    if (openData.email) {
      if (!isEmail(openData.email)) {
        hasError = true;
        errorsCopy.email = "mailErr";
      }
      if (!openData.password) {
        hasError = true;
        errorsCopy.password = "emptyField";
      } else if (openData.password.length < 8) {
        hasError = true;
        errorsCopy.password = "passErr";
      }
      if (
        openData.password !== "" &&
        openData.confirmPassword !== openData.password
      ) {
        hasError = true;
        errorsCopy.confirmPassword = "confirmPwdErr";
      }

      // if one address field was filed all need to be mandatory
      if (openData.address || openData.city || openData.state || openData.zip) {
        hasAddress = true;
        if (!openData.address) {
          errorAddress = true;
          errorsCopy.address = "emptyField";
        }
        if (!openData.city) {
          errorAddress = true;
          errorsCopy.city = "emptyField";
        }
        if (!openData.state) {
          errorAddress = true;
          errorsCopy.state = "emptyField";
        }
        if (!openData.country) {
          errorAddress = true;
          errorsCopy.country = "selectErr";
        }
        if (!openData.zip) {
          errorAddress = true;
          errorsCopy.zip = "emptyField";
        }
      }
    }
    setErrors(errorsCopy);
    try {
      if (!hasError && !errorAddress) {
        const newParticipant = {
          name: openData.name,
        };
        const newArtist = {
          label_id: openData.label_id,
          name: openData.name,
          description: openData.description || openData.name,
          is_featured: false,
          user_id: userId,
        };
        let user = null;
        if (openData.email) {
          user = {
            email: openData.email,
            password: openData.password,
            confirmPassword: openData.confirmPassword,
            profile: {},
          };

          if (openData.number) {
            user.profile.phone = {
              country: openData.countryPhone,
              code: openData.code,
              number: openData.number,
            };
          }
          if (hasAddress) {
            user.profile.address = {
              address: openData.address,
              city: openData.city,
              state: openData.state,
              country: openData.country,
              zip: openData.zip,
            };
          }
        }
        const createdArtist = await Admin.addArtist(
          newArtist,
          user,
          imageFile ? imageFile.originFileObj : null
        );
        if (!createdArtist.errors) {
          newParticipant.artist_id = createdArtist._id;
          newParticipant.image = createdArtist.image;
          getArtists();
          await getArtists(newParticipant);
          notification.success({
            message: dictionary.artistSuccess,
          });
        } else {
          notification.error({
            message: dictionary.errorGeneral,
          });
        }
        setIsModalVisible(false);
      } else {
        setIsModalVisible(true);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitUpdate = async () => {
    setLoadArtist(true);
    let hasError,
      errorAddress,
      hasAddress = false;
    let errorsCopy = {
      name: "",
      address: "",
      city: "",
      state: "",
      zip: "",
    };
    if (!openData.name) {
      hasError = true;
      errorsCopy.name = "emptyField";
    }
    if (!openData._id) {
      hasError = true;
      // the id user not exists
    }

    // if one address field was filed all need to be mandatory
    if (openData.address || openData.city || openData.state || openData.zip) {
      hasAddress = true;
      if (!openData.address) {
        errorAddress = true;
        errorsCopy.address = "emptyField";
      }
      if (!openData.city) {
        errorAddress = true;
        errorsCopy.city = "emptyField";
      }
      if (!openData.state) {
        errorAddress = true;
        errorsCopy.state = "emptyField";
      }
      if (!openData.country) {
        errorAddress = true;
        errorsCopy.country = "selectErr";
      }
      if (!openData.zip) {
        errorAddress = true;
        errorsCopy.zip = "emptyField";
      }
    }
    setErrors(errorsCopy);
    try {
      if (!hasError && !errorAddress) {
        setIsModalVisible(false);
        let newArtist = {
          artist_id: openData._id,
          name: openData.name,
          description: openData.description || openData.name,
          is_featured: false,
        };

        let profile = {};

        if (openData.number) {
          profile.phone = {
            country: openData.countryPhone,
            code: openData.code,
            number: openData.number,
          };
        }
        if (hasAddress) {
          profile.address = {
            address: openData.address,
            city: openData.city,
            state: openData.state,
            country: openData.country,
            zip: openData.zip,
          };
        }
        const updatedArtist = await ArtistCalls.update(
          newArtist,
          profile,
          imageFile && imageFile.originFileObj
            ? imageFile.originFileObj
            : imageFile
        );
        if (updatedArtist) {
          getArtists();
          setLoadArtist(false);
          setIsModalVisible(false);
          notification.success({
            message: dictionary.artistUpdated,
          });
        } else {
          setLoadArtist(false);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };


  return (
    <div className="general_information user-detls">
      {loadingAdding ? <Loader className="load-add" /> : null}
      <Typography.Title level={3} className="row-flex-btwn">
        {dictionary.artistList}
        <Button
          key="cancel"
          size="sm"
          text={dictionary.addArtist}
          onClick={addArtistInUser}
        />
      </Typography.Title>
      <Row justify="space-around" className="user-div">
        <Col xs={24} lg={24} className="info-one">
          <div className="table-admin inline-contend">
            <Table
              rowKey="index"
              columns={columns}
              pagination={artists.lenght > 10 ? true : false}
              dataSource={dataSource}
              className="content-table-admin users-artistlist"
            />
          </div>
        </Col>
      </Row>
      <AddArtist
        type={null}
        colab={false}
        hidedsp={true}
        errors={errors}
        artist={openData}
        headArtist={false}
        labelArtist={null}
        labels={userLabels}
        imageUrl={imageUrl}
        setImage={setImage}
        loading={loadingImage}
        loadArtist={loadArtist}
        urlOriginal={urlOriginal}
        handleChange={handleChange}
        handleCancel={handleCancel}
        handleSubmit={handleSubmit}
        dataCountries={dataCountries}
        changeHandler={changeHandler}
        phoneCountries={phoneCountries}
        isModalVisible={isModalVisible}
        onChangeCheckbox={onChangeCheckbox}
        handleSubmitUpdate={handleSubmitUpdate}
        selectChangeHandler={selectChangeHandler}
      />
    </div>
  );
};

export default ArtistsList;