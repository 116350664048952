import React, { useContext, useState, useEffect } from "react";
import { Row, Col } from "antd";
import { LanguageContext } from "../../../../context/Language";
import { AppContext } from "../../../../context/App";
import Radio from "../../../Form/Radio";
import Select from "../../../Form/Select";
import Button from "../../../Buttons/GeneralButton";
import Loader from "../../../Loader";

const Start = ({
  state,
  setInput,
  nextSection,
  validateStep,
  changeSection,
  createRelease,
}) => {

  const {dictionary, languagesList} = useContext(LanguageContext);
  const {formatOptions, labels} = useContext(AppContext);
  const [dataLabels, setLabels] = useState([]);
  const [generalError, setGeneralError] = useState(null);
  const [loader, setLoader] = useState(false);
  const [errors, setError] = useState({
    formatErr: false,
    labelErr: false,
    langErr: false,
    isNewErr: false,
  });

  const exampleRadio = [
    { value: "yes", name: dictionary.yes },
    { value: "no", name: dictionary.no },
  ];

  useEffect(() => {
    if(dataLabels.length>1){
      setInput({
        ...state,
        label: 0,
      })
    }
  }, [dataLabels]);

  useEffect(() => {
    getLabels();
  }, [labels]);

  useEffect(() => {
    validateStep(next);
    // eslint-disable-next-line
  }, [state.format, state.lang, state.label]);

  const getLabels = async () => {
    try {
      const labelsArray = labels || [];
      setLabels(labelsArray);
    } catch (e) {
      setLabels([]);
    }
  };


  const changeRadio = (e) => {
    const isNew = e.target.value === "yes";
    setInput({
      ...state,
      isNew,
    });
  };

  const handleSelect = (e, name) => {
    if (name === "label") {
      if (e !== 0) {
        const getLabelName = dataLabels.find((item) => item._id === e).name;
        setInput({
          ...state,
          [name]: e,
          infoC: state.infoC.length !== 0 ? state.infoC : getLabelName,
          infoP: state.infoP.length !== 0 ? state.infoP : getLabelName,
          holder: state.holder.length !== 0 ? state.holder : getLabelName,
        });
      } else {
        setInput({
          ...state,
          [name]: e,
        });
      }
    } else if (name === "lang") {
      setInput({
        ...state,
        lang: e,
        metadata: e,
      });
    } else {
      setInput({
        ...state,
        [name]: e,
      });
    }
  };

  const validate = () => {
    let data = null;
    let error = false;
    let errors = {
      formatErr: false,
      labelErr: false,
      langErr: false,
      isNewErr: false,
    };
    setError({
      ...errors,
      errors,
    });
    if (state.format === 0) {
      errors.formatErr = true;
      error = true;
    }
    if (state.label === 0) {
      errors.labelErr = true;
      error = true;
    }
    if (state.lang === 0) {
      errors.langErr = true;
      error = true;
    }
    if (!error) {
      data = true;
    } else {
      setError({
        ...errors,
        errors,
      });
    }
    return data;
  };

  const next = async (n = null) => {
    if (validate() && n) {
      let error = false;
      if (state._id) {
        setLoader(true);
        const save = await createRelease();
        setLoader(false);
        error = save.error;
      }
      if (!error) {
        changeSection(n);
        nextSection(n);
      } else {
        setGeneralError(dictionary[error] || dictionary.releaseCheckData);
      }
    }

    return false;
  };

  // const styleUnableToUpdateFormat = state.tracks.length
  //   ? { height: 40 }
  //   : "";

  return (
    <div>
      <div className="forms">
        <Row className="gutter-row">
          <Col xs={24} sm={24} md={12} lg={12} className="col1">
            <Select
              disabled={state.tracks.length}
              error={
                state.tracks.length ? dictionary.unableToUpdateFormat : null
              }
              name="format"
              required={true}
              value={state.format}
              options={formatOptions}
              label={dictionary.format}
              placeholder={dictionary.select}
              onChange={(e) => handleSelect(e, "format")}
              className={
                (errors.formatErr ? "countErr " : "") + " selector-general"
              }
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} className="col2">
            {dataLabels.length ? (
              <Select
                showSearch={true}
                name="label"
                required={true}
                options={dataLabels}
                value={state.label}
                label={dictionary.label}
                placeholder={dictionary.select}
                onChange={(e) => handleSelect(e, "label")}
                className={
                  (errors.labelErr ? "countErr " : "") + " selector-general"
                }
              />
            ) : (
              <Select
                name="label"
                required={true}
                options={[]}
                label={dictionary.label}
                placeholder={dictionary.select}
                className="selector-general labels-na"
              />
            )}
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} className="col1">
            <Select
              name="lang"
              required={true}
              showSearch={true}
              disableOpt={true}
              value={state.lang}
              options={languagesList}
              label={dictionary.language}
              placeholder={dictionary.select}
              onChange={(e) => handleSelect(e, "lang")}
              className={
                (errors.langErr ? "countErr " : "") + " selector-general"
              }
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} className="col2">
            <Radio
              required={true}
              value={state.isNew ? "yes" : "no"}
              onChange={changeRadio}
              options={exampleRadio}
              className="radio-options"
              label={dictionary.isNewRelease}
            />
          </Col>
          {generalError ? (
            <div className="error-field">{generalError}</div>
          ) : null}
          {loader ? <Loader /> : null}
        </Row>
      </div>
      <div className="align-right">
        <Button text={dictionary.next} size="md" onClick={() => next(1)} />
      </div>
    </div>
  );
};

export default Start;
