import React, { useContext, useEffect, useState } from "react";
import { Layout as Chart } from "antd";
import { UserContext } from "../../context/User";
import UserMenu from "../../components/Navbar/UserMenu";
import Navbar from "../../components/Navbar";
import UploadCard from "../../components/UploadCard";
import { useLocation } from "react-router-dom";

const Layout = ({
  children,
  open,
  current = "/",
  setOpen,
  changeItem,
  showDrawer,
}) => {
  const location = useLocation();
  const { sessionAuth } = useContext(UserContext);
  const [isLogged, setIsLogged] = useState(current);
  
  useEffect(() => {
    current = location.pathname;
    let isLoged = sessionAuth
    ? current.includes("/newRelease")
      ? "/release"
      : current
    : current;
    setIsLogged(isLoged);
  }, [location.pathname]);

  return (
    <div className="content">
      <Chart>
        <Navbar
          open={open}
          current={isLogged}
          setOpen={setOpen}
          changeItem={changeItem}
        />
        <Chart>
          {sessionAuth ? (
            <React.Fragment>
              <UserMenu changeItem={changeItem} showDrawer={showDrawer} />
            </React.Fragment>
          ) : null}
          {children}
        </Chart>
        {/* <Footer/> */}
      </Chart>
      <UploadCard />
    </div>
  );
};

export default Layout;
