import React, { useEffect, useContext, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import { Table, Spin, Dropdown, Input, notification } from "antd";
import { AppContext } from "../../../context/App";
import { LanguageContext } from "../../../context/Language";
import { StopOutlined, CheckCircleOutlined } from "@ant-design/icons";
import Select from "../../Form/Select";
import Search from "../../Form/Search";
import MarkIcon from "../../Icon/MarkIcon";
import SearchIcon from "../../Icon/SearchIcon";
import Button from "../../Buttons/GeneralButton";
//import ArrowDownIcon from "../../Icon/ArrowDownIcon";
import Admin from "../../../classes/admin/admin.jsx";
import moment from "moment";

const TableContracts = ({
  list,
  error,
  columns,
  loadList,
  setLoading,
  showModalContractStatus,
  getContracts,
  offset,
  limit,
  setLimit,
  setWhere,
  setOrder,
  updatePage,
  totalData,
}) => {
  const curveIdRef = useRef(null);
  const { dictionary } = useContext(LanguageContext);
  const { statusContractOptions } = useContext(AppContext);
  const [search, setSearch] = useState(null);
  const [dataSource, setTableData] = useState([]);
  const [errorCurve, setErrorCurve] = useState(false);
  const [errorPayee, setErrorPayee] = useState(false);
  const [stateInput, setInput] = useState({
    payee_id: null,
    curveId: null,
  });
  const [status, setStatus] = useState(0);

  const page = useMemo(() => Math.floor(offset / limit) + 1, [offset, limit]);

  useEffect(() => {
    getList();
    // eslint-disable-next-line
  }, [list, stateInput, errorCurve]);

  const getLastCharacter = (str, n) => {
    let newString = str.substring(str.length - n);
    return newString;
  };

  const handleMenuItemClick = (e) => {
    e.stopPropagation();
  };

  const updatePayeeId = async (idLabel) => {
    setErrorCurve(false);
    setErrorPayee(false);
    setLoading(true);
    try {
      let validate = validateID(2, stateInput.payee_id);
      if (!validate) {
        return;
      }
      Admin.updatePayee(idLabel, stateInput.payee_id).then((resp) => {
        if (resp.status === 200) {
          successPayeeNotification();
          getContracts();
          getList();
        } else {
          errorNotification();
        }
      });
      setInput({
        payee_id: null,
        curveId: null,
      });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const errorNotification = () => {
    notification.error({
      message: dictionary.errorGeneral,
      duration: 3,
      placement: "topRight",
    });
  };

  const successNotification = () => {
    notification.success({
      message: dictionary.contractAddedSuccess,
      duration: 3,
      placement: "topRight",
    });
  };

  const successPayeeNotification = () => {
    notification.success({
      message: dictionary.payeeAddedSuccess,
      duration: 3,
      placement: "topRight",
    });
  };

  const validateID = (numb, item) => {
    if (!item || item.length === 0) {
      if (numb === 1) {
        setErrorCurve(dictionary.emptyField);
      } else {
        setErrorPayee(dictionary.emptyField);
      }
      return false;
    }
    return true;
  };

  const createContract = (idLabel) => {
    setErrorCurve(false);
    setErrorPayee(false);
    setLoading(true);
    let myerror = false;
    try {
      let validate = validateID(1, stateInput.curveId);
      if (!validate) {
        setLoading(false);
        return;
      }
      Admin.addContract(curveIdRef.current, idLabel)
        .then((resp) => {
          if (resp.status === 200) {
            successNotification();
            getContracts();
            getList();
          } else {
            if (resp.status === 400) {
              myerror = dictionary[resp.errors[0].message];
            } else if (resp.status === 404) {
              errorNotification();
            }
          }
          setErrorCurve(myerror);
          setLoading(false);
        })
        .finally(() => {
          setInput({
            payee_id: null,
            curveId: null,
          });
        });
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const changeHandler = (e) => {
    setErrorCurve(false);
    setErrorPayee(false);
    const { name, value } = e.target;
    if (name === "curveId") {
      curveIdRef.current = value;
    }
    setInput({
      ...stateInput,
      [name]: value,
    });
  };

  const openDropdown = (el) => {
    const items = [
      {
        key: "0",
        label: (
          <div
            className="item-drop-contracts"
            onClick={(e) => handleMenuItemClick(e)}
          >
            <h3>{el.name}</h3>
            <label>{dictionary.payee} ID</label>
            <div className="group-drop-contracts">
              <Input
                name="payee_id"
                defaultValue={stateInput.payee_id ?? el.payee_id}
                onChange={(e) => changeHandler(e)}
                className={
                  (errorPayee ? "errorcurve " : "") +
                  " input-general input-edit"
                }
                placeholder={dictionary.placeholderId}
              />
              {errorPayee ? (
                <p className="errorPayee-text">{errorPayee}</p>
              ) : null}
              <Button
                size="sm"
                className="new-rel-btn save"
                text={dictionary.save}
                onClick={() => updatePayeeId(el._id)}
              />
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to="https://app.curveroyaltysystems.com/#/payees/new"
              >
                <Button
                  size="md"
                  className="new-rel-btn create-payee"
                  text={dictionary.createPayee}
                />
              </Link>
            </div>
            <label>{dictionary.contract} ID</label>
            <div className="list-contracts">
              {el.contracts.length === 0
                ? ""
                : el.contracts.map((contract, index) => (
                    <div key={index} className="contract-item">
                      {contract._id}
                      {contract.status === "active" ? (
                        <StopOutlined
                          className="disable-detail"
                          onClick={() =>
                            showModalContractStatus(contract._id, "disable")
                          }
                        />
                      ) : (
                        <CheckCircleOutlined
                          className="active-detail"
                          onClick={() =>
                            showModalContractStatus(contract._id, "enable")
                          }
                        />
                      )}
                    </div>
                  ))}
            </div>
            <div className="group-drop-contracts">
              <Input
                name="curveId"
                defaultValue={stateInput.curveId}
                onChange={changeHandler}
                className={
                  (errorCurve ? "errorcurve " : "") +
                  " input-general input-edit"
                }
                placeholder={dictionary.placeholderId}
              />
              {errorCurve ? (
                <p className="errorCurve-text">{errorCurve}</p>
              ) : null}
              <Button
                size="sm"
                className="new-rel-btn save"
                text={dictionary.add}
                onClick={() => {
                  createContract(el._id);
                }}
              />
              <Link
                rel="noopener noreferrer"
                to={`/contracts/create?labelId=${el._id}`}
              >
                <Button
                  size="md"
                  className="new-rel-btn create-payee"
                  text={dictionary.createContract}
                />
              </Link>
            </div>
          </div>
        ),
      },
    ];
    return (
      <Dropdown
        trigger={["click"]}
        overlayClassName="drop-contract"
        menu={{
          items,
          selectable: true,
          defaultSelectedKeys: [el._id],
          // onClick: (e) => handlePriority(e.key, relId),
        }}
        placement="bottomRight"
        overlayClickTrigger="never"
      >
        <div className="edit-value-icon" onClick={(e) => e.preventDefault()}>
          <MarkIcon />
          {/*<ArrowDownIcon className="open-edit" />*/}
        </div>
      </Dropdown>
    );
  };

  const getList = async () => {
    setTableData([]);
    let listArray = [];
    list.map((el, index) => {
      let assigned = el.assigned_contract ? (
        <div className="yes-ac">{dictionary.yes}</div>
      ) : (
        <div className="no-ac">{dictionary.no}</div>
      );
      let estatus =
        el.status === "Pending" ? (
          <div className="pend-status">{dictionary.pending}</div>
        ) : el.status === "Synced" ? (
          <div className="sync-status">{dictionary.synced}</div>
        ) : (
          "-"
        );
      let shortPayee = el.payee_id ? getLastCharacter(el.payee_id, 10) : null;
      let payeeID = shortPayee ? (
        <Link
          target="_blank"
          rel="noopener noreferrer"
          className="link-table"
          to={
            "https://app.curveroyaltysystems.com/#/payees/" +
            el.payee_id +
            "/edit"
          }
        >
          ...{shortPayee}
        </Link>
      ) : (
        "-"
      );
      let curveId =
        el.contracts && el.contracts[0] ? el.contracts[0]._id : null;
      let contractName =
        el.contracts && el.contracts[0] ? el.contracts[0].name : "-";
      let contractLink = curveId ? (
        <Link
          target="_blank"
          rel="noopener noreferrer"
          className="link-table"
          to={"/contracts/update?c=" + curveId}
        >
          {contractName}
        </Link>
      ) : (
        "-"
      );
      return listArray.push({
        index: index,
        key: el._id || index,
        id: index + 1,
        name: el.name,
        contracts: contractLink,
        assigned_contract: assigned,
        payee_id: payeeID,
        status: estatus,
        editValues: openDropdown(el),
        created_date: el.created_date
          ? moment(el.created_date).format("MMM DD, YYYY, h:mm a")
          : "-",
        sublabels: el.sublabels ?? null,
      });
    });
    setTableData(listArray);
  };

  const onSearch = (search) => {
    setSearch(search.target.value);
  };

  const handleSelect = (e) => {
    setStatus(e);
  };

  useEffect(() => {
    const newWhere = [];
    if (status) {
      newWhere.push({ status: { $like: `/^${status}$/` } });
    }
    if (search) {
      newWhere.push({
        $or: [
          { name: { $like: `/${search}/` } },
          { main_contract: { $like: `/${search}/` } },
          { payee_id: { $like: `/${search}/` } },
          { curve: { $like: `/${search}/` } },
          { "contracts.curve_id": { $like: `/${search}/` } },
        ],
      });
    }
    setWhere(newWhere.length > 0 ? { $and: newWhere } : {});
  }, [status, search]);

  const expandedRowRender = (record) => {
    let subLabels = record.sublabels;
    const subColumn = [
      {
        title: dictionary.sublabel,
        dataIndex: "name",
        key: "name",
        className: "title-category",
      },
      {
        title: dictionary.contract,
        dataIndex: "contracts",
        key: "contracts",
        className: "title-category",
      },
      {
        title: dictionary.payee,
        dataIndex: "payee_id",
        key: "payee_id",
        className: "title-category",
      },
      {
        title: dictionary.editValues,
        dataIndex: "editValues",
        className: "title-category",
      },
    ];
    const data = [];
    subLabels.map((el, index) => {
      let shortPayee = el.payee_id ? getLastCharacter(el.payee_id, 10) : null;
      let payeeID = shortPayee ? (
        <Link
          target="_blank"
          rel="noopener noreferrer"
          className="link-table"
          to={
            "https://app.curveroyaltysystems.com/#/payees/" +
            el.payee_id +
            "/edit"
          }
        >
          ...{shortPayee}
        </Link>
      ) : (
        "-"
      );
      let curveId =
        el.contracts && el.contracts[0] ? el.contracts[0]._id : null;
      let contractName =
        el.contracts && el.contracts[0] ? el.contracts[0].name : "-";
      let contractLink = curveId ? (
        <Link
          target="_blank"
          rel="noopener noreferrer"
          className="link-table"
          to={"/contracts/update?c=" + curveId}
        >
          {contractName}
        </Link>
      ) : (
        "-"
      );
      return data.push({
        index: index,
        key: el._id || index,
        name: el.name ?? "-",
        contracts: contractLink,
        payee_id: payeeID,
        editValues: openDropdown(el),
      });
    });
    return (
      <Table
        columns={subColumn}
        dataSource={data}
        pagination={false}
        className="content-subtable"
      />
    );
  };

  return (
    <div className="tables">
      <div className="top-actions-srch">
        <div className="searchers">
          <Search
            name="searcher"
            value={search}
            mobile="responsive"
            onSearch={onSearch}
            className="searcher sec back"
            enterButton={<SearchIcon />}
            placeholder={dictionary.search}
          />
          <Select
            name="section"
            value={status}
            options={statusContractOptions}
            placeholder={"Status"}
            onChange={(e) => handleSelect(e)}
            className="selector-general search-sec"
          />
        </div>
      </div>
      <div className="general-card-admin">
        <div className="table-admin inline-contend">
          <div className="header-tab-ad"></div>
          <Spin spinning={loadList} className="spinner spin-contract">
            <Table
              rowKey="index"
              columns={columns}
              pagination={{
                current: page,
                total: totalData,
                pageSize: limit,
                onChange: (page) => updatePage(page),
              }}
              dataSource={dataSource}
              className="content-table-admin users contracts"
              expandable={{
                expandedRowRender,
                rowExpandable: (record) =>
                  record.sublabels.length !== 0 && record.sublabels !== null,
              }}
              onChange={(pagination, _filters, sorter) => {
                setLimit(pagination.pageSize);
                setOrder([
                  [sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"],
                ]);
              }}
            />
          </Spin>
        </div>
        {error ? <p className="text-error relative-text">{error}</p> : null}
      </div>
    </div>
  );
};

export default TableContracts;
